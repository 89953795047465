import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getRequestOptions } from 'app/shared/utils/http-request.utils';
import {
  BudgetSegmentAccessResponse,
  BudgetSegmentAmountDO,
  BudgetSegmentDO
} from '../../types/segment.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeepPartial } from '../../types/deep-partial.type';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { SegmentAmountsByTimeframes } from '@shared/types/object-amounts.interface';
import { PfmV3Service } from '../base/pfm-v3.service';

@Injectable({
  providedIn: 'root'
})
export class BudgetSegmentService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly http = inject(HttpClient);
  private readonly v3_api_manager = inject(PfmV3Service);

  public apiPaths = {
    budgetSegment: 'company_budget_segment/',
    availableSegment: 'available/',
    multiCreate: 'multi_create/',
    multiUpdate: 'multi_update/',
    companyBudgetSegmentAmount: 'company_budget_segment_amount/',
    amountsByTimeframes: 'amounts_by_timeframes/',
    updateSegmentAmountsByTimeframes: 'update_segment_amounts_by_timeframes/',
    updateSegmentForecastAllocationByTimeframes: 'update_forecast_allocation_by_timeframe/',

  };

  getAvailableBudgetSegments(budgetId: number): Observable<BudgetSegmentAccessResponse> {
    const requestUrl =
      `${this.apiV2Url}${this.apiPaths.budgetSegment}${this.apiPaths.availableSegment}`;
    return this.http.get<BudgetSegmentAccessResponse>(
      requestUrl,
      getRequestOptions({ budget: budgetId })).pipe(
        map((data: BudgetSegmentAccessResponse) => {
          data.available_segments = (data.available_segments || []).map(item => ({ ...item, budget: budgetId }));
          return data;
        })
      );
  }

  getBudgetSegments(budgetId: number): Observable<BudgetSegmentDO[]> {
    return this.http.get<BudgetSegmentDO[]>(
      `${this.apiV2Url}${this.apiPaths.budgetSegment}`,
      getRequestOptions({ budget: budgetId })
    );
  }

  updateBudgetSegment(segmentId, segment: Partial<BudgetSegmentDO>): Observable<any> {
    return this.http.patch(
    `${this.apiV2Url}${this.apiPaths.budgetSegment}${segmentId}/`,
      JSON.stringify(segment)
    );
  }

  deleteBudgetSegment(segmentId: number): Observable<any> {
    return this.http.delete<any>(`${this.apiV2Url}${this.apiPaths.budgetSegment}${segmentId}/`);
  }

  createListOfSegments(segments: DeepPartial<BudgetSegmentDO>[]): Observable<BudgetSegmentDO[]> {
    const { budgetSegment, multiCreate } = this.apiPaths;
    return this.http.post<BudgetSegmentDO[]>(
    `${this.apiV2Url}${budgetSegment}${multiCreate}`,
      JSON.stringify(segments)
    );
  }

  createBudgetSegmentAmounts(values: Partial<BudgetSegmentAmountDO>[]): Observable<BudgetSegmentAmountDO[]> {
    const { companyBudgetSegmentAmount, multiCreate } = this.apiPaths;
    return this.http.post<BudgetSegmentAmountDO[]>(
    `${this.apiV2Url}${companyBudgetSegmentAmount}${multiCreate}`,
      JSON.stringify(values)
    );
  }

  updateBudgetSegmentAmounts(values: Partial<BudgetSegmentAmountDO>[]): Observable<BudgetSegmentAmountDO[]> {
    const { companyBudgetSegmentAmount, multiUpdate } = this.apiPaths;
    return this.http.patch<BudgetSegmentAmountDO[]>(
    `${this.apiV2Url}${companyBudgetSegmentAmount}${multiUpdate}`,
      JSON.stringify(values)
    );
  }

  getAmountsByTimeframes(budgetId: number, companyId: number, ids: number[], params?: object): Observable<SegmentAmountsByTimeframes> {
    
    // Combining Normal and Custom Field Filters with POST request

    let payload = { ...params, budget: budgetId, ids: ids.join(','), company: companyId };
    
    if(payload['custom_fields'] && !Object.keys(payload['custom_fields']).length) {
      delete payload['custom_fields']
    }

    // v3 endpoint for getting amounts by timeframes for segments
    return this.v3_api_manager.post<SegmentAmountsByTimeframes>(
      `${this.apiPaths.budgetSegment}${this.apiPaths.amountsByTimeframes}`,
       { ...payload }
    )

  }

  updateBudgetSegmentAmountsByTimeframe(segmentId: number, data: Partial<BudgetSegmentAmountDO>[]): Observable<BudgetSegmentAmountDO[]> {
    
    let payload = []; 
    let segmentData = data[0];
    
    if(segmentData['type'] === 'forecast') {
      payload.push({
        company_budget_alloc: segmentData['company_budget_alloc'],
        forecast_amount: segmentData['amount'],
      });

      // v3 endpoint for updating forecast allocation by timeframes for segments
      return this.v3_api_manager.patch<BudgetSegmentAmountDO[]>(
        `${this.apiPaths.budgetSegment}${segmentId}/${this.apiPaths.updateSegmentForecastAllocationByTimeframes}`,
        payload
      )
    } else {
      delete data[0]['type'];
      payload = [...data]

      // v3 endpoint for updating segment amounts by timeframes for segments
      return this.v3_api_manager.patch<BudgetSegmentAmountDO[]>(
        `${this.apiPaths.budgetSegment}${segmentId}/${this.apiPaths.updateSegmentAmountsByTimeframes}`,
        payload
      )
    }
  }
}
